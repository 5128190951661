import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { facebookLib } from '@lib/facebook'
import { Booking } from 'types/model/booking'
import { analytics } from '@lib/analytics'
import { PurchaseTrackingData } from 'types/lib/analytics'

export function usePageview() {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = () => {
      facebookLib.trackPageview({ url: window.location.href })
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => router.events.off('routeChangeComplete', handleRouteChange)
  }, [router.events])

  useEffect(() => {
    facebookLib.trackPageview({ url: window.location.href })
  }, [])
}

export function usePurchaseConfirmation(booking: Booking) {
  useEffect(() => {
    if (Date.now() - booking.modified > 30_000) return

    const purchaseData: PurchaseTrackingData = {
      name: booking.name,
      firstName: booking.primaryUser.firstName as string,
      lastName: booking.primaryUser.lastName as string,
      email: booking.primaryUser.email as string,
      userId: booking.primaryUser.id,
      bookingId: booking.id,
      venueId: booking.venue.id,
      eventId: booking.event,
      currency: booking.venue.currencyCode,
      value: (booking.total ?? 0).toString()
    }

    analytics.trackPurchase(purchaseData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
