import { withClientSide } from '@utils/client-side'
import { logger } from './logger'

export const fbqEvents = {
  pageView: 'PageView',
  addToCart: 'AddToCart',
  initiateCheckout: 'InitiateCheckout',
  purchase: 'Purchase'
} as const

export type FBQEventKey = keyof typeof fbqEvents
export type FBQEvent = (typeof fbqEvents)[keyof typeof fbqEvents]

async function withFBQ(callback: (fbq: Window['fbq']) => void | Promise<void>) {
  return await withClientSide(async () => {
    if (!window.fbq) return
    return await callback(window.fbq)
  })
}

function track(evt: FBQEvent, data: Record<string, any>) {
  return withFBQ((fbq) => {
    logger.log(`FB Tracking: ${evt}`)
    return fbq('track', evt, data)
  })
}

function trackPageview(data: { url: string }) {
  return track(fbqEvents.pageView, data)
}

function trackAddToCart(data: Record<string, any>) {
  return track(fbqEvents.addToCart, data)
}

function trackCheckoutStart(data: Record<string, any>) {
  return track(fbqEvents.initiateCheckout, data)
}

function trackPurchase(data: Record<string, any>) {
  return track(fbqEvents.purchase, data)
}

export const facebookLib = {
  trackPageview,
  trackAddToCart,
  trackCheckoutStart,
  trackPurchase
}
