// process.env for next.js runtime, import.meta.env for vite runtime
export const env = {
  mode: process.env.NODE_ENV || import.meta.env.MODE,
  domain: process.env.NEXT_PUBLIC_SITE_DOMAIN || import.meta.env.VITE_PUBLIC_SITE_DOMAIN,
  tablelistApiUrl:
    (process.env.NEXT_PUBLIC_TABLELIST_API_URL || import.meta.env.VITE_PUBLIC_TABLELIST_API_URL) ??
    'https://api.tablelist.com',
  tablelistApiKey:
    process.env.NEXT_PUBLIC_TABLELIST_API_KEY || import.meta.env.VITE_PUBLIC_TABLELIST_API_KEY,
  bugsnagApiKey:
    process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || import.meta.env.VITE_PUBLIC_BUGSNAG_API_KEY,
  stripeApiKey: process.env.NEXT_PUBLIC_STRIPE_KEY || import.meta.env.VITE_PUBLIC_STRIPE_KEY,
  pubnubPubKey:
    process.env.NEXT_PUBLIC_PUBNUB_PUB_KEY || import.meta.env.VITE_PUBLIC_PUBNUB_PUB_KEY,
  pubnubSubKey: process.env.NEXT_PUBLIC_PUBNUB_SUB_KEY || import.meta.env.VITE_PUBLIC_PUBNUB_SUB_KEY
}

export const isProd = env.tablelistApiUrl.includes('api.tablelist.com')
