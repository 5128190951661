import { ReactElement, ReactNode } from 'react'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { SWRConfig } from 'swr'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/next'

import { usePageview } from '@hooks/analytics'
import { BugsnagContext } from '@contexts/bugsnag'
import '@styles/globals.css'
import { GlobalFontSans } from '@styles/font'

type FormattedNextPage = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type FormattedAppProps = AppProps & {
  Component: FormattedNextPage
}

export default function App({ Component, pageProps }: FormattedAppProps) {
  const getLayout = Component.getLayout || ((page) => page)

  usePageview()

  return (
    <>
      <SWRConfig
        value={{ revalidateOnMount: true, revalidateOnFocus: true, dedupingInterval: 60_000 }}
      >
        <Head>
          <meta name='viewport' content='width=device-width, user-scalable=no' />
        </Head>
        <BugsnagContext>
          <GlobalFontSans />
          <main id='app-main' className='w-full h-full overflow-y-auto font-sans antialiased'>
            {getLayout(<Component {...pageProps} />)}
          </main>
        </BugsnagContext>
      </SWRConfig>
      <Analytics />
      <SpeedInsights />
    </>
  )
}
