import {
  CheckoutTrackingData,
  PageviewTrackingData,
  PurchaseTrackingData
} from 'types/lib/analytics'

import { facebookLib } from './facebook'

async function trackPageview({ url }: PageviewTrackingData) {
  await facebookLib.trackPageview({ url })
}

async function trackCheckout(vals: CheckoutTrackingData) {
  await facebookLib.trackCheckoutStart(vals)
}

async function trackPurchase(vals: PurchaseTrackingData) {
  await facebookLib.trackPurchase(vals)
}

export const analytics = {
  trackPageview,
  trackCheckout,
  trackPurchase
}
