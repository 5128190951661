import { Inter as FontSans } from "next/font/google"

export const fontSans = FontSans({
  subsets: ["latin"],
  variable: "--font-sans",
})

export function GlobalFontSans() {
  return (
    <style jsx global>{`
      html {
        font-family: ${fontSans.style.fontFamily};
      }
    `}</style>
  )
}
