import {
  GetServerSidePropsContext,
  GetServerSidePropsResult,
  GetStaticPathsResult,
  GetStaticPropsContext,
  GetStaticPropsResult
} from 'next'
import { ApiError } from '@lib/api/errors'

export const isServer = () => typeof window === 'undefined'

export class SSGRedirectError extends Error {
  path: string

  constructor(path: string, message: string) {
    super(message)
    this.path = path
  }
}

async function withErrorHandling<T extends (...args: any[]) => any>(callback: T) {
  try {
    return await callback()
  } catch (err: any) {
    if (err instanceof SSGRedirectError) {
      return {
        redirect: {
          statusCode: 302,
          destination: err.path
        },
        revalidate: 10
      }
    }
    if (err instanceof ApiError && err.status === 404) {
      return {
        notFound: true
      }
    }
    throw err
  }
}

export function ssrProps<P = Record<string, any>>(
  getProps: (ctx: GetServerSidePropsContext) => Promise<P> | P
): (ctx: GetServerSidePropsContext) => Promise<GetServerSidePropsResult<P>> {
  return async (ctx: GetServerSidePropsContext) =>
    withErrorHandling(async () => {
      const props = await getProps(ctx)
      return {
        props
      }
    })
}

export function ssgProps<P = Record<string, any>>(
  getProps: (ctx: GetStaticPropsContext) => Promise<P> | P
): (ctx: GetStaticPropsContext) => Promise<GetStaticPropsResult<P>> {
  return async (ctx: GetStaticPropsContext) =>
    withErrorHandling(async () => {
      const props = await getProps(ctx)
      return {
        props,
        revalidate: 10
      }
    })
}

export function ssgPaths(paths?: GetStaticPathsResult['paths']) {
  return () => {
    return {
      paths: paths ?? [],
      fallback: 'blocking'
    }
  }
}
