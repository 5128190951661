import React, { ReactNode, useEffect, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { env } from '@lib/env'
import { isServer } from '@utils/server'

export function BugsnagContext({ children }: { children: ReactNode }) {
  const [started, setStarted] = useState<boolean>(false)

  useEffect(() => {
    // Trying to avoid multiple Bugsnag.start calls which results in an annoying log message
    if (!started && env.bugsnagApiKey) {
      Bugsnag.start({
        apiKey: env.bugsnagApiKey as string,
        plugins: [new BugsnagPluginReact()]
      })
      setStarted(true)
    }
  }, [started])

  // Only wan to call this client side.
  const ErrorBoundary = !isServer()
    ? Bugsnag.getPlugin('react')?.createErrorBoundary(React)
    : undefined
  if (!ErrorBoundary) return <>{children}</>
  return <ErrorBoundary>{children}</ErrorBoundary>
}
