import { FontWeight } from 'types/lib'
const foreground = '#4B545A'
const neutral = '#f7f7f7'

const commonStyle = `
  margin: 4px;
  padding: 0 2px;
  font-family: Tahoma, sans-serif, system-ui;
`

const borderStyles = `
  border-style: solid;
  border-width: 1px;
  border-color: #f7f7f754;
`

export class Logger {
  label: string

  constructor(label: string) {
    this.label = label
  }

  log(...logArgs: any[]) {
    const badgeStyle = Logger.getLogStyle({
      color: neutral,
      fontWeight: 'bold',
      bordered: true,
      backgroundColor: foreground
    })
    console.log(`%c${this.label}`, badgeStyle, ...logArgs)
  }

  info(...logArgs: any[]) {
    const badgeStyle = Logger.getLogStyle({
      color: neutral,
      fontWeight: 'bold',
      bordered: true,
      backgroundColor: '#0472D9'
    })
    console.info(`%c${this.label}`, badgeStyle, ...logArgs)
  }

  success(...logArgs: any[]) {
    const badgeStyle = Logger.getLogStyle({
      color: neutral,
      fontWeight: 'bold',
      bordered: true,
      backgroundColor: '#71B287'
    })
    console.log(`%c${this.label}`, badgeStyle, ...logArgs)
  }

  warn(...logArgs: any[]) {
    const badgeStyle = Logger.getLogStyle({
      color: neutral,
      fontWeight: 'bold',
      bordered: true,
      backgroundColor: `#ED7E49`
    })
    console.warn(`%c${this.label}`, badgeStyle, ...logArgs)
  }

  error(...logArgs: any[]) {
    const badgeStyle = Logger.getLogStyle({
      color: neutral,
      fontWeight: 'bold',
      bordered: true,
      backgroundColor: '#CE463E'
    })
    console.error(`%c${this.label}`, badgeStyle, ...logArgs)
  }

  async group(groupLabel: string, printFunc: () => void | Promise<void>) {
    const badgeStyle = Logger.getLogStyle({
      color: neutral,
      fontWeight: 'bold',
      bordered: true,
      backgroundColor: '#6A55EE'
    })
    console.group(`%c${this.label}`, badgeStyle, groupLabel)
    await printFunc()
    console.groupEnd()
  }

  static getLogStyle({
    color = foreground,
    fontWeight = 'normal',
    bordered = false,
    backgroundColor = 'transparent'
  }: {
    color?: string
    fontWeight?: FontWeight
    bordered?: boolean
    backgroundColor?: string
  } = {}) {
    return `
    color: ${color};
    font-weight: ${fontWeight};
    background-color: ${backgroundColor};
    font-size: 10px;
    ${bordered ? borderStyles : ''}
    ${commonStyle}
  `
  }
}

export const logger = new Logger('TL')
